import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { AppBar, Drawer, Icon, IconButton, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Map, { LineOptions } from "./Map";
import Details from "./Details";
import Settings from "./Settings";
import Config from "./config";
import { IpType, Run, UpdateRunsModel } from "./models";
import { groupBy, intersection, intersectionBy, keys, mapValues, unionBy, uniq, uniqBy, without } from "lodash";
import * as atlas from "azure-maps-control";
import * as atlas2 from "azure-maps-drawing-tools";
import axios from "axios";
import dayjs from "dayjs";
import toGeoJSON from "@mapbox/togeojson";
import JSZip from "jszip";
import MapMath from "./math";
import utmZonesData from "./zones.json";

export const DateFormat = "dddd, D MMMM YYYY";

const AustraliaBounds = [113.338953078, -43.6345972634, 153.569469029, -10.6681857235];

const useStyles = makeStyles(theme => ({
    menu: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    title: {
        flex: 1
    },
    drawer: {
        width: theme.breakpoints.width("sm"),
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    }
}));

type AppProps = {
    config: Config
};

const App: FunctionComponent<AppProps> = ({ config }) => {
    // Lookup data for filters.
    const [sensors, setSensors] = useState<{ [serialNumber: string]: string }>({});
    const [jobs, setJobs] = useState<{ [jobNumber: string]: string }>({});
    const [flights, setFlights] = useState<string[]>([]);

    // Filters.
    const [selectedSensorSerialNumbers, setSelectedSensorSerialNumbers] = useState<string[]>([]);
    const [selectedJobNumbers, setSelectedJobNumbers] = useState<string[]>([]);
    const [selectedFlightNames, setSelectedFlightNames] = useState<string[]>([]);
    const [selectedIpTypes, setSelectedIpTypes] = useState<IpType[]>([]);

    const [dates, setDates] = useState<{ from: dayjs.Dayjs, to: dayjs.Dayjs }>({
        from: null,
        to: null
    });

    const [showUtmZones, setShowUtmZones] = useState(true);

    const [hoveredRuns, setHoveredRuns] = useState<Run[]>([]);
    const [selectedRuns, setSelectedRuns] = useState<Run[]>([]);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const map = useRef<atlas.Map>();
    const [ready, setReady] = useState(false);
    const classes = useStyles();

    useEffect(() => applyFilters(), [
        selectedSensorSerialNumbers,
        selectedJobNumbers,
        selectedFlightNames,
        selectedIpTypes,
        dates]);

    useEffect(() => {
        const utmZonesLayer = map.current.layers.getLayerById("utm-zones") as atlas.layer.LineLayer;

        if (utmZonesLayer) {
            utmZonesLayer.setOptions({ visible: showUtmZones });
        }
    }, [showUtmZones]);

    useEffect(() => {
        const data = map.current.sources.getById("lines") as atlas.source.DataSource;

        if (Boolean(data)) {
            loadFilterLookupData(data);
        }
    }, [selectedSensorSerialNumbers, selectedJobNumbers, selectedFlightNames]);

    useEffect(() => {
        if (selectedJobNumbers.length > 0) {
            const lastJobNumber = selectedJobNumbers[selectedJobNumbers.length - 1];
            const lines = map.current.sources.getById("lines") as atlas.source.DataSource;
            const jobShapes = lines.getShapes().filter(shape => shape.getProperties().jno === lastJobNumber);
            const hull = atlas.math.getConvexHull(jobShapes);
            const bounds = atlas.data.BoundingBox.fromData(hull);

            flyTo(bounds);
        }
    }, [selectedJobNumbers]);

    useEffect(() => {
        const hoveredLayer = map.current.layers.getLayerById("hovered") as atlas.layer.LineLayer;

        if (!hoveredLayer) {
            return;
        }

        const hoveredRunIds = hoveredRuns.map(r => r.id);
        const filter: any = [...hoveredLayer.getOptions().filter];

        // Update filter array parameter.
        // ["in", ["get", "id"], ["literal", selectedRunIds]]
        filter[2][1] = hoveredRunIds;

        hoveredLayer.setOptions({ filter });
    }, [hoveredRuns]);

    useEffect(() => {
        const selectedLayer = map.current.layers.getLayerById("selected") as atlas.layer.LineLayer;

        if (!selectedLayer) {
            return;
        }

        const selectedRunIds = selectedRuns.map(r => r.id);
        const filter: any = [...selectedLayer.getOptions().filter];

        // Update filter array parameter.
        // ["in", ["get", "id"], ["literal", selectedRunIds]]
        filter[2][1] = selectedRunIds;

        selectedLayer.setOptions({ filter });
    }, [selectedRuns]);

    /**
     * Applies selected filters to the lines layer.
     */
    const applyFilters = () => {
        const linesLayer = map.current.layers.getLayerById("lines") as atlas.layer.LineLayer;

        if (!linesLayer) {
            return;
        }

        const filters: any /*atlas.Expression[]*/ = [];

        if (selectedSensorSerialNumbers.length > 0) {
            filters.push(["in", ["get", "ssn"], ["literal", selectedSensorSerialNumbers]]);
        }

        if (selectedJobNumbers.length > 0) {
            filters.push(["in", ["get", "jno"], ["literal", selectedJobNumbers]]);
        }

        if (selectedFlightNames.length > 0) {
            filters.push(["in", ["get", "pn"], ["literal", selectedFlightNames]]);
        }

        if (selectedIpTypes.length > 0) {
            filters.push(["in", ["get", "it"], ["literal", selectedIpTypes]]);
        }

        const { from, to } = dates;

        if (Boolean(from)) {
            filters.push([">", ["get", "pts"], ["literal", from.unix()]]);
        }

        if (Boolean(to)) {
            filters.push(["<", ["get", "pts"], ["literal", to.unix()]]);
        }

        linesLayer.setOptions({
            filter: filters.length > 0 ? ["all", ...filters] : null
        });
    };

    const resetSettings = () => {
        setSelectedSensorSerialNumbers([]);
        setSelectedJobNumbers([]);
        setSelectedFlightNames([]);
        setSelectedIpTypes([]);

        setDates({
            from: null,
            to: null
        });

        setShowUtmZones(true);

        flyTo(AustraliaBounds);
    };

    const onMapReady = () => {
        const drawingManager = new atlas2.drawing.DrawingManager(map.current, {
            toolbar: new atlas2.control.DrawingToolbar({
                buttons: ["draw-polygon", "draw-rectangle"],
                position: "top-left",
                style: "light"
            })
        });

        map.current.events.add("drawingmodechanged", drawingManager, mode => {
            if (mode.startsWith("draw")) {
                drawingManager.getSource().clear();
            }
        });

        map.current.events.add("drawingcomplete", drawingManager, area => {
            drawingManager.getSource().clear();
            drawingManager.setOptions({ mode: atlas2.drawing.DrawingMode.idle });

            const linesLayer = map.current.layers.getLayerById("lines");
            const shapes = map.current.layers.getRenderedShapes(null, linesLayer) as atlas.Shape[];
            const shapesInSelection = MapMath.shapesIntersectPolygon(shapes, area, "line");
            const runs = uniqBy(shapesInSelection.map(s => s.getProperties() as Run), "id");

            setSelectedRuns(runs);
        });

        flyTo(AustraliaBounds);
    }

    const onMapLoaded = async (data: atlas.source.DataSource) => {
        loadFilterLookupData(data);

        // AOI source.
        var aoi = new atlas.source.DataSource("aoi");
        map.current.sources.add(aoi);

        // UTM zones source. This is read from a file included in the project.
        var utmZones = new atlas.source.DataSource("utm-zones");
        utmZones.add(new atlas.data.FeatureCollection(utmZonesData.features));
        map.current.sources.add(utmZones);

        const aoiPolygonsLayer = new atlas.layer.PolygonLayer(aoi, "aoi-polygons", {
            fillColor: "blue",
            fillOpacity: 0.5,
            filter: ["any", ["==", ["geometry-type"], atlas.data.Polygon.TYPE], ["==", ["geometry-type"], atlas.data.MultiPolygon.TYPE]]
        });

        const aoiLinesLayer = new atlas.layer.LineLayer(aoi, "aoi-lines", {
            strokeColor: "blue",
            strokeOpacity: 0.5,
            filter: ["any", ["==", ["geometry-type"], atlas.data.LineString.TYPE], ["==", ["geometry-type"], atlas.data.MultiLineString.TYPE]],
        });

        // Add "UTM zones" layer.
        const utmZonesLayer = new atlas.layer.LineLayer(utmZones, "utm-zones", {
            strokeWidth: 3,
            strokeOpacity: 0.5,
            strokeColor: "blue"
        });

        // Add flight lines layer.
        const linesLayer = new atlas.layer.LineLayer(data, "lines", LineOptions);

        // Add "selected" layer.
        const selectedLayer = new atlas.layer.LineLayer(data, "selected", {
            ...LineOptions,
            strokeOpacity: 1.0,
            strokeColor: "yellow",
            // M$ haven't included the "in" expression name in their TypeScript definitions
            // so cast to "any" to avoid getting a compile error.
            filter: ["in", ["get", "id"], ["literal", []]] as any
        });

        // Add "hover" layer.
        const hoverLayer = new atlas.layer.LineLayer(data, "hovered", {
            ...LineOptions,
            strokeOpacity: 0.5,
            strokeColor: "white",
            // M$ haven't included the "in" expression name in their TypeScript definitions
            // so cast to "any" to avoid getting a compile error.
            filter: ["in", ["get", "id"], ["literal", []]] as any
        });

        // Add layers.
        map.current.layers.add([aoiPolygonsLayer, aoiLinesLayer, utmZonesLayer, linesLayer, selectedLayer, hoverLayer]);

        map.current.events.add("click", e => {
            const event = e.originalEvent as MouseEvent;

            if (e.shapes.length === 0 && !event.ctrlKey) {
                setSelectedRuns([]);
            }
        });

        map.current.events.add("click", linesLayer, e => {
            const event = e.originalEvent as MouseEvent;
            const runs = e.shapes.map((s: atlas.Shape) => s.getProperties() as Run);

            setSelectedRuns(prev => {
                if (event.ctrlKey) {
                    const all = unionBy(prev, runs, "id");
                    const remove = intersectionBy(prev, runs, "id");

                    return without(all, ...remove);
                } else {
                    return runs;
                }
            });

            //e.shapes.forEach((shp: atlas.Shape) => {
            // const ds = new atlas.source.DataSource();
            // map.current.sources.add(ds);

            // const bounds: number[][] = shp.getProperties().b;
            // const positions = bounds.map(point => new atlas.data.Position(point[0], point[1]));
            // positions.push(new atlas.data.Position(bounds[0][0], bounds[0][1]));

            // ds.add(new atlas.data.Feature(new atlas.data.Polygon(positions)));

            // map.current.layers.add(new atlas.layer.PolygonLayer(ds, null, {
            //     fillColor: "blue",
            //     fillOpacity: 0.5
            // }));
            //});
        });

        map.current.events.add("mouseenter", linesLayer, e => {
            e.map.getCanvasContainer().style.cursor = "pointer";
            setHoveredRuns(e.shapes.map((s: atlas.Shape) => s.getProperties() as Run));
        });

        map.current.events.add("mouseleave", linesLayer, e => {
            map.current.getCanvasContainer().style.cursor = "grab";
            setHoveredRuns([]);
        });

        const container = map.current.getMapContainer();
        container.addEventListener("dragover", onDragOver);
        container.addEventListener("drop", onDrop);

        setReady(true);
    };

    /**
     * Populates the filter lookup data based on the loaded map data.
     */
    const loadFilterLookupData = (data: atlas.source.DataSource) => {
        // Extract run fields from shape.
        const runs = data.getShapes().map(shape => shape.getProperties() as Run);

        // Extract unique sensor and job number values for filter lookups.
        const uniqueSensors = uniqBy(runs.map(r => ({
            serialNumber: r.ssn,
            type: r.st
        })), sd => sd.serialNumber);

        const uniqueJobs = uniqBy(runs
            .filter(r => selectedSensorSerialNumbers.length === 0 || selectedSensorSerialNumbers.includes(r.ssn))
            .map(r => ({
                number: r.jno,
                name: r.jn
            })), sd => sd.number);

        const uniqueFlights = uniq(runs
            .filter(r => (selectedSensorSerialNumbers.length === 0 || selectedSensorSerialNumbers.includes(r.ssn)) &&
                (selectedJobNumbers.length === 0 || selectedJobNumbers.includes(r.jno)))
            .map(r => r.pn));

        const sensorsBySerialNumber = groupBy(uniqueSensors, s => s.serialNumber);
        const sensorTypesBySerialNumber = mapValues(sensorsBySerialNumber, s => s[0].type);

        const jobsByJobNumber = groupBy(uniqueJobs, j => j.number);
        const jobNamesByNumber = mapValues(jobsByJobNumber, j => j[0].name);

        setSensors(sensorTypesBySerialNumber);
        setJobs(jobNamesByNumber);
        setFlights(uniqueFlights);

        const newSelectedJobNumbers = intersection(selectedJobNumbers, keys(jobsByJobNumber));
        const newSelectedFlightNames = intersection(selectedFlightNames, uniqueFlights);

        if (newSelectedJobNumbers.length !== selectedJobNumbers.length) {
            setSelectedJobNumbers(newSelectedJobNumbers);
        }

        if (newSelectedFlightNames.length !== selectedFlightNames.length) {
            setSelectedFlightNames(newSelectedFlightNames);
        }
    };

    const onDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        e.dataTransfer.dropEffect = "copy";
    };

    const onDrop = async (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const aoi = map.current.sources.getById("aoi") as atlas.source.DataSource;
        const { files } = e.dataTransfer;
        const invalidFiles: string[] = [];

        aoi.clear();

        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            const fileName = file.name.toUpperCase();

            if (fileName.endsWith(".JSON") || fileName.endsWith(".GEOJSON")) {
                if (!renderGeoJsonAoi(await file.text(), aoi)) {
                    invalidFiles.push(file.name);
                }
            } else if (fileName.endsWith(".KML")) {
                if (!renderKmlAoi(await file.text(), aoi)) {
                    invalidFiles.push(file.name);
                }
            } else if (fileName.endsWith(".KMZ")) {
                if (!renderKmlAoi(await readFirstKmlFileInZipArchive(file), aoi)) {
                    invalidFiles.push(file.name);
                }
            } else {
                invalidFiles.push(file.name);
            }
        }

        if (invalidFiles.length > 0) {
            alert(`The following AOIs couldn't be loaded:\n - ${invalidFiles.join("\n - ")}\n\nNote only KML and KMZ files are supported.`);
        }

        if (invalidFiles.length === files.length) {
            // All files were invalid so don't bother doing anything else.
            return;
        }

        // Reposition camera to AOI.
        const hull = atlas.math.getConvexHull(aoi.getShapes());
        const bounds = atlas.data.BoundingBox.fromData(hull);

        flyTo(bounds);
    };

    const readFirstKmlFileInZipArchive = async (file: File) => {
        const zip = await JSZip.loadAsync(file);
        const kmls = zip.filter((_, entry) => entry.name.toUpperCase().endsWith(".KML"));

        if (kmls.length === 0) {
            alert("No KML files were found in the KMZ.");
            return null;
        }

        return await kmls[0].async("text");
    };

    const renderGeoJsonAoi = (json: string, aoi: atlas.source.DataSource): boolean => {
        if (!json) {
            return false;
        }

        const data: atlas.data.FeatureCollection = JSON.parse(json);
        const { features } = data;

        if (features.length === 0) {
            return false;
        }

        // Add features to AOI layer.
        aoi.add(features);

        return true;
    };

    const renderKmlAoi = (kml: string, aoi: atlas.source.DataSource): boolean => {
        if (!kml) {
            return false;
        }

        // Convert KML to GeoJson.
        const document = new DOMParser().parseFromString(kml, "text/xml");
        const data: atlas.data.FeatureCollection = toGeoJSON.kml(document);
        const { features } = data;

        if (features.length === 0) {
            return false;
        }

        // Add features to AOI layer.
        aoi.add(features);

        return true;
    };

    const flyTo = (bounds: atlas.data.BoundingBox) =>
        map.current.setCamera({
            bounds,
            padding: 50,
            type: "fly",
            duration: 2000
        });

    const onSave = async (model: UpdateRunsModel) => {
        const headers = {
            "Authorization": `Bearer ${localStorage.getItem("adal.idtoken")}`
        };

        const response = await axios.patch(`${config.apiBaseUrl}/lines`, model, { headers });

        if (response.status !== 200) {
            return false;
        }

        const lines = map.current.sources.getById("lines") as atlas.source.DataSource;

        for (const runId of model.runIds) {
            const shape = lines.getShapeById(runId);
            const { jobNumber, jobName, ipType } = model;

            shape.addProperty("jno", jobNumber);
            shape.addProperty("jn", jobName);
            shape.addProperty("it", ipType);
        }

        if (!(model.jobNumber in jobs)) {
            // Push new job number onto job filter lookup data.
            setJobs(prev => ({
                ...prev,
                [model.jobNumber]: model.jobName
            }));
        }

        setSelectedRuns([]);

        return true;
    };

    return <>
        {/* Map */}
        <Map sourceUrl={`${config.apiBaseUrl}/lines`}
            mapRef={map}
            onReady={onMapReady}
            onLoaded={onMapLoaded}
        />

        {/* Details drawer */}
        <Drawer open={selectedRuns.length > 0}
            classes={{ paper: classes.drawer }}
            anchor="right"
            variant="persistent"
            onClose={() => setSelectedRuns([])}>
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography className={classes.title} color="inherit" variant="h6">Details ({selectedRuns.length} runs)</Typography>
                    <IconButton edge="end" onClick={() => setSelectedRuns([])}>
                        <Icon>clear</Icon>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Details runs={selectedRuns} contributorsGroupId={config.contributorsGroupId} onSave={onSave} />
        </Drawer>

        {/* Settings drawer */}
        <Drawer open={settingsOpen}
            classes={{ paper: classes.drawer }}
            anchor="right"
            onClose={() => setSettingsOpen(false)}>
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography className={classes.title} color="inherit" variant="h6">Settings</Typography>
                    <IconButton edge="end" onClick={() => setSettingsOpen(false)}>
                        <Icon>clear</Icon>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Settings sensors={sensors}
                sensorSerialNumbers={selectedSensorSerialNumbers}
                onSensorSerialNumbersChange={value => setSelectedSensorSerialNumbers(value)}
                jobs={jobs}
                jobNumbers={selectedJobNumbers}
                onJobNumbersChange={value => setSelectedJobNumbers(value)}
                flights={flights}
                flightNames={selectedFlightNames}
                onFlightNamesChange={value => setSelectedFlightNames(value)}
                fromDate={dates.from}
                toDate={dates.to}
                onDatesChange={(from, to) => setDates({ from, to })}
                ipTypes={selectedIpTypes}
                onIpTypesChange={value => setSelectedIpTypes(value)}
                showUtmZones={showUtmZones}
                onShowUtmZonesChange={value => setShowUtmZones(value)}
                onReset={resetSettings}
            />
        </Drawer>

        <IconButton className={classes.menu}
            color="primary"
            disabled={!ready}
            onClick={() => setSettingsOpen(panelOpen => !panelOpen)}>
            <Icon fontSize="large">settings</Icon>
        </IconButton>
    </>;
};

export default App;