import React from "react";
import ReactDOM from "react-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import App from "./App";
import Config from "./config";
import axios from "axios";
import merge from "deepmerge";
import config from "./config.json";

// Don't throw exceptions unless status code in 500 range.
axios.defaults.validateStatus = status => status < 500;

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#E1103D"
        },
        secondary: {
            main: "#46545F"
        }
    }
});

const main = (config: Config) => {
    const app = <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <CssBaseline />
            <App config={config} />
        </MuiPickersUtilsProvider>
    </ThemeProvider>;

    ReactDOM.render(app, document.getElementById("root"));
};

const loadConfig = (url: string): Promise<Config> => {
    if (process.env.NODE_ENV === "development") {
        return Promise.resolve(config);
    }

    return new Promise((res, rej) => {
        axios.get<Config>(url)
            .then(response => res(merge(config, response.data)))
            .catch(rej);
    });
};

loadConfig("./config.json")
    .catch(error => console.log("Failed to load remote config file!", error))
    .then(main)
    .catch(error => console.log("Unexpected error!", error));