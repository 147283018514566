import React, { FunctionComponent, useMemo } from "react";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControlLabel, FormGroup, ListItemText, Switch, TextField, TextFieldProps } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { keys } from "lodash";
import dayjs from "dayjs";
import { DateFormat } from "./App";
import { IpType } from "./models";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    switch: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    resetButton: {
        marginTop: theme.spacing(1)
    }
}));

type SettingsProps = {
    // Sensors filter.
    sensors: { [serialNumber: string]: string };
    sensorSerialNumbers: string[];
    onSensorSerialNumbersChange: (serialNumbers: string[]) => void;

    // Jobs filter.
    jobs: { [number: string]: string };
    jobNumbers: string[];
    onJobNumbersChange: (jobNumbers: string[]) => void;

    // Flights filter.
    flights: string[];
    flightNames: string[];
    onFlightNamesChange: (flightNames: string[]) => void;

    // Project date filter.
    fromDate: dayjs.Dayjs;
    toDate: dayjs.Dayjs;
    onDatesChange: (from: dayjs.Dayjs, to: dayjs.Dayjs) => void;

    // IP type filter.
    ipTypes: IpType[];
    onIpTypesChange: (value: IpType[]) => void;

    showUtmZones: boolean;
    onShowUtmZonesChange: (value: boolean) => void;

    onReset: () => void;
};

const Settings: FunctionComponent<SettingsProps> = props => {
    const {
        sensors,
        sensorSerialNumbers,
        onSensorSerialNumbersChange,
        jobs,
        jobNumbers,
        onJobNumbersChange,
        flights,
        flightNames,
        onFlightNamesChange,
        fromDate,
        toDate,
        onDatesChange,
        ipTypes,
        onIpTypesChange,
        showUtmZones,
        onShowUtmZonesChange,
        onReset
    } = props;

    const allSensorSerialNumbers = useMemo(() => keys(sensors).sort((a, b) => a.localeCompare(b)), [sensors]);
    const allJobNumbers = useMemo(() => keys(jobs).sort((a, b) => a.localeCompare(b)), [jobs]);
    const allFlightNames = useMemo(() => flights.sort((a, b) => a.localeCompare(b)), [flights]);
    const classes = useStyles();

    const textFieldProps: TextFieldProps = {
        fullWidth: true,
        margin: "normal",
        InputLabelProps: {
            shrink: true
        }
    };

    return <div className={classes.root}>
        <Autocomplete
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            options={allSensorSerialNumbers}
            value={sensorSerialNumbers}
            getOptionLabel={o => `${o} - ${sensors[o]}`}
            onChange={(_, ssn) => onSensorSerialNumbersChange(ssn)}
            renderOption={snn => <ListItemText primary={snn} secondary={sensors[snn]} />}
            renderInput={params => <TextField {...params}
                {...textFieldProps}
                autoFocus
                label="Sensor/s"
                placeholder={sensorSerialNumbers.length === 0 ? "Any" : null} />}
        />
        <Autocomplete
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            options={allJobNumbers}
            value={jobNumbers}
            getOptionLabel={o => `${o || "???"} - ${jobs[o]}`}
            onChange={(_, jobNumbers) => onJobNumbersChange(jobNumbers)}
            renderOption={jobNumber => <ListItemText primary={jobNumber || "???"} secondary={jobs[jobNumber]} />}
            renderInput={params => <TextField {...params}
                {...textFieldProps}
                label="Job/s"
                placeholder={jobNumbers.length === 0 ? "Any" : null} />}
        />
        <Autocomplete
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            options={allFlightNames}
            value={flightNames}
            getOptionLabel={o => o}
            onChange={(_, flightNames) => onFlightNamesChange(flightNames)}
            renderOption={flightName => <ListItemText primary={flightName} />}
            renderInput={params => <TextField {...params}
                {...textFieldProps}
                label="Flight/s"
                placeholder={flightNames.length === 0 ? "Any" : null} />}
        />
        <Autocomplete
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            options={["Client", "Shared"]}
            value={ipTypes}
            onChange={(_, it) => onIpTypesChange(it as IpType[])}
            renderOption={it => <ListItemText primary={it} />}
            renderInput={params => <TextField {...params}
                {...textFieldProps}
                label="IP owner/s"
                placeholder={ipTypes.length === 0 ? "Any" : null} />}
        />
        <DatePicker
            label="From date"
            fullWidth
            margin="normal"
            clearable
            autoOk
            format={DateFormat}
            maxDate={toDate || undefined}
            value={fromDate}
            emptyLabel="No date selected"
            onChange={e => onDatesChange(e, toDate)}
        />
        <DatePicker
            label="To date"
            fullWidth
            margin="normal"
            clearable
            autoOk
            format={DateFormat}
            minDate={fromDate || undefined}
            value={toDate}
            emptyLabel="No date selected"
            onChange={e => onDatesChange(fromDate, e)}
        />
        <FormGroup row className={classes.switch}>
            <FormControlLabel label="Show UTM zones"
                control={<Switch
                    color="primary"
                    checked={showUtmZones}
                    onChange={e => onShowUtmZonesChange(e.target.checked)} />}
            />
        </FormGroup>
        <Button className={classes.resetButton}
            color="primary"
            variant="contained"
            onClick={() => onReset()}>
            Reset
        </Button>
    </div>;
};

export default Settings;