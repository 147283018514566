import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Run, UpdateRunsModel } from "./models";
import { Button, Checkbox, FormControl, FormControlLabel, TextField } from "@material-ui/core";
import { decode } from "js-base64";
import dayjs from "dayjs";
import { DateFormat } from "./App";
import { uniq } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(2)
    }
}));

type DetailsProps = {
    runs: Run[];
    contributorsGroupId: string;
    onSave: (model: UpdateRunsModel) => Promise<boolean>;
};

const Details: FunctionComponent<DetailsProps> = ({ runs, contributorsGroupId, onSave }) => {
    if (runs.length === 0) {
        return null;
    }

    const idToken = localStorage.getItem("adal.idtoken");
    let editable = false;

    if (idToken) {
        const parts = idToken.split(".");
        const claims = JSON.parse(decode(parts[1])) as { roles: string[] };

        // "Spatial Index Contributor" AD security group.
        editable = claims.roles.includes(contributorsGroupId);
    }

    const projectDates = uniq(runs.map(r => r.pd));
    const projectNames = uniq(runs.map(r => r.pn));
    const sensorSerialNumbers = uniq(runs.map(r => r.ssn));
    const sensorTypes = uniq(runs.map(r => r.st));
    const sensorConfigurations = uniq(runs.map(r => r.sc));
    const runNumbers = uniq(runs.map(r => r.rn));
    const flownNumbers = uniq(runs.map(r => r.fn));
    const swathNames = uniq(runs.map(r => r.sn));

    const projectDate = projectDates.length === 1 ? projectDates[0] : null;
    const projectName = projectNames.length === 1 ? projectNames[0] : null;
    const sensorSerialNumber = sensorSerialNumbers.length === 1 ? sensorSerialNumbers[0] : null;
    const sensorType = sensorTypes.length === 1 ? sensorTypes[0] : null;
    const sensorConfiguration = sensorConfigurations.length === 1 ? sensorConfigurations[0] : null;
    const runNumber = runNumbers.length === 1 ? runNumbers[0] : null;
    const flownNumber = flownNumbers.length === 1 ? flownNumbers[0] : null;
    const swathName = swathNames.length === 1 ? swathNames[0] : null;

    const [model, setModel] = useState<UpdateRunsModel>({});
    const [busy, setBusy] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        const runIds = runs.map(r => r.id);
        const jobNumbers = uniq(runs.map(r => r.jno));
        const jobNames = uniq(runs.map(r => r.jn));
        const ipTypes = uniq(runs.map(r => r.it));

        setModel({
            runIds,
            jobNumber: jobNumbers.length === 1 ? jobNumbers[0] : "",
            jobName: jobNames.length === 1 ? jobNames[0] : "",
            ipType: ipTypes.length === 1 ? ipTypes[0] : "Client"
        });
    }, [runs]);

    const onChange = (name: keyof UpdateRunsModel, value: string) => {
        setModel(prev => ({
            ...prev,
            [name]: value
        }))
    };

    const save = async () => {
        try {
            setBusy(true);

            const saved = await onSave(model);

            if (!saved) {
                alert("An error occurred while saving your changes. Please try again.");
            }
        } finally {
            setBusy(false);
        }
    };

    return <div className={classes.root}>
        {Boolean(projectDate) && <TextField
            label="Project date"
            fullWidth
            margin="normal"
            value={dayjs(projectDate).format(DateFormat)}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(projectName) && <TextField
            label="Flight name"
            fullWidth
            margin="normal"
            value={projectName}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(sensorSerialNumber) && <TextField
            label="Sensor serial number"
            fullWidth
            margin="normal"
            value={sensorSerialNumber}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(sensorType) && <TextField
            label="Sensor type"
            fullWidth
            margin="normal"
            value={sensorType}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(sensorConfiguration) && <TextField
            label="Sensor configuration"
            fullWidth
            margin="normal"
            value={sensorConfiguration}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(swathName) && <TextField
            label="Swath name"
            fullWidth
            margin="normal"
            value={swathName}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(runNumber) && Boolean(flownNumber) && <TextField
            label="Run number (planned / flown)"
            fullWidth
            margin="normal"
            value={`${runNumber} / ${flownNumber}`}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(runNumber) && !Boolean(flownNumber) && <TextField
            label="Planned run number"
            fullWidth
            margin="normal"
            value={runNumber}
            InputProps={{
                disableUnderline: true
            }}
        />}
        {Boolean(flownNumber) && !Boolean(runNumber) && <TextField
            label="Flown run number"
            fullWidth
            margin="normal"
            value={flownNumber}
            InputProps={{
                disableUnderline: true
            }}
        />}
        <TextField
            autoFocus={editable}
            label="Job number"
            fullWidth
            margin="normal"
            value={model.jobNumber || ""}
            onChange={e => onChange("jobNumber", e.target.value)}
            InputProps={{
                readOnly: !editable,
            }}
        />
        <TextField
            label="Job name"
            fullWidth
            margin="normal"
            value={model.jobName || ""}
            onChange={e => onChange("jobName", e.target.value)}
            InputProps={{
                readOnly: !editable
            }}
        />
        <FormControl fullWidth margin="normal" disabled={!editable}>
            <FormControlLabel
                label="Shared IP"
                control={<Checkbox color="primary"
                    checked={model.ipType === "Shared"}
                    onChange={e => onChange("ipType", e.target.checked ? "Shared" : "Client")}
                />}
            />
        </FormControl>
        {editable && <Button
            className={classes.button}
            color="primary"
            disabled={busy || !model.jobNumber || !model.jobName}
            variant="contained"
            onClick={() => save()}>
            Save
            </Button>}
    </div>;
};

export default Details;