import React, { FunctionComponent, MutableRefObject, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as atlas from "azure-maps-control";

const useStyles = makeStyles({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }
});

type MapProps = {
    sourceUrl: string;
    mapRef?: MutableRefObject<atlas.Map>;
    onReady?: () => void;
    onLoaded: (data: atlas.source.DataSource) => void;
};

export const LineOptions: atlas.LineLayerOptions = {
    strokeColor: "red",
    strokeOpacity: 0.5,
    strokeWidth: 7
};

const Origin: atlas.data.Position = [133, -28.5];

const Map: FunctionComponent<MapProps> = ({ sourceUrl, onReady, onLoaded, mapRef }) => {
    const container = useRef<HTMLDivElement>(null);
    const map = useRef<atlas.Map>();
    const classes = useStyles();

    useEffect(() => {
        // One-time initialisation of map.
        map.current = mapRef.current = createMap();
    }, []);

    const createMap = () => {
        const map = new atlas.Map(container.current, {
            //center: Origin,
            //zoom: 4.5,
            language: "en-AU",
            authOptions: {
                authType: atlas.AuthenticationType.aad,
                aadTenant: "7c8ae5f7-4306-4319-be4b-275d8bf23b30",
                aadAppId: "82662f73-c578-4075-b366-f2e204283a6e",
                clientId: "28f5f68c-7303-4e61-8b33-8f8283c08e91"
            },
            style: "satellite_road_labels",
            disableTelemetry: true,
            showLogo: false,
            showFeedbackLink: false
        });

        map.events.addOnce("ready", e => onMapReady(e.map));
        map.events.addOnce("load", e => onMapLoad(e.map));

        return map;
    };

    const onMapReady = (map: atlas.Map) => {
        // Add map controls.
        const controls = [
            new atlas.control.CompassControl(),
            new atlas.control.PitchControl(),
            new atlas.control.ZoomControl()
        ];

        map.controls.add(controls, {
            position: atlas.ControlPosition.BottomRight
        });

        onReady && onReady();
    };

    const onMapLoad = async (map: atlas.Map) => {
        // Load lines data.
        const lines = new atlas.source.DataSource("lines");

        console.log("Loading lines data...");
        const start = Date.now();

        await lines.importDataFromUrl(sourceUrl);

        const finish = Date.now();
        console.log(`Lines data loaded (${finish - start} ms).`);

        map.sources.add(lines);

        onLoaded(lines);
    };

    return <div className={classes.root} ref={container} />;
};

export default Map;